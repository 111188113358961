class Schedule {
  constructor() {
    this.init();
  }

  init() {
    this.initSchedules();
    this.initUpcomingSchedules();
  }

  initSchedules() {
    const schedules = document.querySelectorAll("[data-schedule]");
    const now = Date.now();
    schedules.forEach((schedule) => {
      const scheduleItems = schedule.querySelectorAll("[data-schedule-item]");
      Array.from(scheduleItems).some((item) => {
        const targetDate = new Date(item.dataset.scheduleItem * 1000);
        if (targetDate < now) {
          item.classList.add("is-inactive");
          return false;
        } else {
          return true;
        }
      });
    });
  }

  initUpcomingSchedules() {
    const schedules = document.querySelectorAll("[data-schedule-upcoming]");

    const now = Date.now();
    const earliest = new Date(2020, 7, 21, 18, 0);
    const startTime = Math.max(now, earliest);

    let twohours = new Date(startTime);
    twohours.setHours(twohours.getHours() + 2);

    schedules.forEach((schedule) => {
      const scheduleItems = schedule.querySelectorAll("[data-schedule-item]");
      const scheduleSlots = schedule.querySelectorAll("[data-schedule-slot]");
      Array.from(scheduleItems).forEach((item) => {
        let slotTimes = item.dataset.scheduleItem;
        if (slotTimes.includes("-")) {
          slotTimes = slotTimes.split("-");
          const startTargetDate = new Date(slotTimes[0] * 1000);
          const endTargetDate = new Date(slotTimes[1] * 1000);

          if (startTargetDate > startTime || endTargetDate <= now) {
            item.classList.add("is-hidden");
          } else {
            item.classList.remove("is-hidden");
          }
        } else {
          const startTargetDate = new Date(slotTimes * 1000);

          if (startTargetDate < startTime || startTargetDate > twohours) {
            item.classList.add("is-hidden");
          } else {
            item.classList.remove("is-hidden");
          }
        }
      });
      Array.from(scheduleSlots).forEach((item) => {
        const count = item.querySelectorAll(
          "[data-schedule-item]:not(.is-hidden)"
        ).length;
        if (count === 0) {
          item.classList.add("is-hidden");
        } else {
          item.classList.remove("is-hidden");
        }
      });
    });
  }
}

export default Schedule;
