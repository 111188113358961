import React from "react";
import ReactDOM from "react-dom";
import Select from "react-select";

const selectStyle = {};

class ReactSelectMounter {
    constructor() {
        this.init();
    }

    init() {
        const multiselects = document.querySelectorAll(
            "select[data-react-select]"
        );

        multiselects.forEach((select) => {
            const wrapper = document.createElement("div");
            const options = Array.from(select.options).reduce(
                (acc, opt) => [...acc, { value: opt.value, label: opt.text }],
                []
            );
            const selectedOptions = Array.from(select.options).reduce(
                (acc, opt) =>
                    opt.selected
                        ? [...acc, { value: opt.value, label: opt.text }]
                        : acc,
                []
            );
            const styles = {
                control: () => "",
            };
            ReactDOM.render(
                <Select
                    options={options}
                    isMulti={true}
                    name={select.name}
                    defaultValue={selectedOptions}
                    onInputChange={(ev) => {
                        const evt = new Event("change");
                        wrapper.closest("form").dispatchEvent(evt);
                    }}
                    styles={styles}
                    classNamePrefix="react-select"
                />,
                wrapper
            );
            select.parentNode.replaceChild(wrapper, select);
        });
    }
}

export default ReactSelectMounter;
