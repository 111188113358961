class TableOfContents {
  constructor() {
    this.init();
  }

  init() {
    const toc = document.querySelector("[data-toc]");
    if (!toc) {
      return;
    }
    const headers = toc.parentElement.parentElement.querySelectorAll("h2");
    const list = document.createElement("ul");
    headers.forEach((header, idx) => {
      header.id = `header-${idx}`;
      let listItem = document.createElement("li");
      let anchorItem = document.createElement("a");
      let textNode = document.createTextNode(header.textContent);
      anchorItem.href = `#header-${idx}`;
      anchorItem.appendChild(textNode);
      listItem.appendChild(anchorItem);
      list.appendChild(listItem);
    });
    toc.appendChild(list);
  }
}

export default TableOfContents;
