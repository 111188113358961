class ToggleBox {
  constructor() {
    this.init();
  }

  init() {
    const toggleBox = document.querySelector("[data-toggle-box]");
    if (toggleBox) {
      const inputs = toggleBox.querySelectorAll("input");
      inputs.forEach((input) => {
        input.addEventListener("change", () => {
          this.filter(toggleBox);
        });
      });
      this.filter(toggleBox);
    }
  }

  filter(toggleBox) {
    const inputs = toggleBox.querySelectorAll("input:checked");
    const keys = Array.from(inputs).reduce(
      (acc, el) => acc.concat(el.value),
      []
    );
    const targets = document.querySelectorAll("[data-toggle-box-target]");
    targets.forEach((el) => {
      const targetKeys = el.dataset.toggleBoxTarget.split(",");
      if (keys.length == 0) {
        el.classList.remove("mod-hidden");
      } else if (
        keys.length === targetKeys.length &&
        keys.every((e) => targetKeys.includes(e))
      ) {
        el.classList.remove("mod-hidden");
      } else {
        el.classList.add("mod-hidden");
      }
    });
  }
}

export default ToggleBox;
