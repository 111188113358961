class PaymentPage {
    init(config) {
        var stripe = Stripe(config.stripePk);
        var elements = stripe.elements();
        var clientSecret = config.stripeIntent;

        var style = {
            base: {
                color: "#32325d",
                fontFamily: '"Proza Libre", sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#7b7b7b"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        };

        var cardElement = elements.create("card", {
            hidePostalCode: true,
            style: style
        });

        cardElement.mount("#card-element");

        var payButton = document.querySelector("[data-pay-button]");
        var nameOnCardField = document.querySelector("[name='name']");
        var errorContainer = document.querySelector("[data-form-errors]");

        payButton.closest("form").addEventListener("submit", e => {
            e.preventDefault();
            errorContainer.textContent = "";
            errorContainer.classList.add("mod-hidden");
            payButton.disabled = true;
            payButton.classList.add("is-loading");
            this.validateBasket(config)
                .then(data => {
                    if (!data["valid"]) {
                        window.location.replace(config.basketUrl);
                    } else {
                        stripe
                            .handleCardPayment(clientSecret, cardElement, {
                                payment_method_data: {
                                    billing_details: {
                                        name: nameOnCardField.value
                                    }
                                }
                            })
                            .then(function (result) {
                                if (result.error) {
                                    errorContainer.textContent = result.error.message;
                                    errorContainer.classList.remove("mod-hidden");
                                    payButton.disabled = false;
                                    payButton.classList.remove("is-loading");
                                } else {
                                    window.location.replace(config.successUrl);
                                }
                            });
                    }
                });
        });
    }

    async validateBasket(config) {
        // Short circuit validation when disabled
        if (config && config.hasOwnProperty("validate") && !config.validate) {
            return Promise.resolve({ "valid": true })
        }
        const res = await fetch("/shop/api/basket/validate/", {
            credentials: "same-origin",
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });
        return await res.json()
    }
}

export default PaymentPage;
