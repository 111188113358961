class EventRPGTimeFilter {
    // RPGs are allowed different start times based on the day selected.
    // This class allows the user to select a day, and then filters the
    // start times based on the day selected.
    // The data-event-rpg-time-filter attribute should be added to a div
    // containing the day and start time fields. The value of
    // data-event-rpg-time-filter should be a JSON object with the day as
    // the key, and an array of start times as the value.
    // Example:
    // <div data-event-rpg-time-filter='{1: ["10:00:00", "14:00:00"], 2: ["10:00:00"]}'>
    constructor() {
        // Because the filterable fields may be dynamically added, we need to
        // find the parent form of these fields and attach the event listener
        // to that.
        const filterables = document.querySelectorAll(
            "[data-event-rpg-time-filter]"
        );
        this.filterableForms = Array.from(filterables).map((filterable) => {
            return filterable.closest("form");
        });
        this.initHandlers();
        this.setInitial();
    }

    initHandlers() {
        this.filterableForms.forEach((form) => {
            form.addEventListener("change", (e) => {
                const group = e.target.closest("[data-event-rpg-time-filter]");
                if (!group) {
                    return;
                }
                this.handler(group);
            });
        });
    }

    setInitial() {
        this.filterableForms.forEach((form) => {
            form.querySelectorAll("[data-event-rpg-time-filter]").forEach(
                (group) => {
                    this.handler(group);
                }
            );
        });
    }

    handler(group) {
        const select = group.querySelector("[name$='-rpg_start_time']");
        const day = group.querySelector("[name$='-day']");
        const filteredChoices = JSON.parse(group.dataset.eventRpgTimeFilter);
        try {
            const newChoices = filteredChoices[parseInt(day.value)];
            this.enableSpecificOptions(select, newChoices);
        } catch (e) {
            return;
        }
    }

    enableSpecificOptions(select, choiceValues) {
        const options = select.querySelectorAll("option");
        options.forEach((option) => {
            if (choiceValues.includes(option.value)) {
                this.enableOption(option);
            } else {
                this.disableOption(option);
            }
        });
    }

    disableOption(option) {
        if (option.parentNode.tagName === "SPAN") {
            return;
        }
        // Wrap the option in a div to disable it
        const wrapper = document.createElement("span");
        option.parentNode.insertBefore(wrapper, option);
        wrapper.appendChild(option);
    }

    enableOption(option) {
        if (option.parentNode.tagName === "SPAN") {
            option.parentNode.replaceWith(option);
        }
    }
}

export default EventRPGTimeFilter;
