class PWAApp {
    constructor(elements, reload) {
        this.elements = elements;
        this.reload = reload;
        this.isPwa = window.matchMedia("(display-mode: standalone)").matches;
        this.init();
    }

    init() {
        if (this.isPwa) {
            this.toggleSections();
            // Sneakily replace home page links with the app homepage
            const homeLinks = document.querySelectorAll('[href="/"]');
            homeLinks.forEach((el) => {
                el.setAttribute("href", "/app");
            });
        }
    }

    toggleSections() {
        const sections = document.querySelectorAll("[data-show-if]");
        if (sections.length !== 0) {
            fetch("/accounts/status/app/", { credentials: "same-origin" })
                .then((res) => res.json())
                .then((data) => {
                    let tags = [];
                    if (data["is_volunteer"]) {
                        tags.push("volunteer");
                    }
                    if (data["running_events"]) {
                        tags.push("running_events");
                    }
                    progressier.add({
                        id: data["user_id"],
                        tags: tags,
                    });
                    sections.forEach((section) => {
                        if (data[section.dataset.showIf]) {
                            section.classList.remove("app-tile--hidden");
                        } else {
                            section.classList.add("app-tile--hidden");
                        }
                    });
                });
        }
    }
}

export default PWAApp;
