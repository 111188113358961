import Emitter from "component-emitter";

class Tabbed {
  constructor() {
    this.init();
  }

  toggleBody(e) {
    const tabId = e.target.dataset.tabId;
    this.emit("toggle");
    const els = e.target
      .closest("[data-tabbed]")
      .querySelectorAll("[data-tab-id]");
    els.forEach((el) => {
      if (el.dataset.tabId === tabId) {
        el.classList.add("is-active");
      } else {
        el.classList.remove("is-active");
      }
    });
  }

  init() {
    this.tabbed = document.querySelectorAll("[data-tabbed]");
    this.tabbed.forEach((tabbed) => {
      tabbed.classList.add("is-active");
      const tabs = tabbed.querySelectorAll("[data-tab]");
      tabs.forEach((toggle) => {
        toggle.removeEventListener("click", this.toggleBody.bind(this));
        toggle.addEventListener("click", this.toggleBody.bind(this));
      });
      var event = new Event("click");
      tabs[0].dispatchEvent(event);
    });
  }
}
Emitter(Tabbed.prototype);

export default Tabbed;
