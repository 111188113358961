class Wysiwyg {
  constructor() {
    this.init();
  }

  init() {
    const editors = document.querySelectorAll("[data-editor] textarea");
    if (editors.length > 0) {
      import(/* webpackChunkName: "wysiwyg" */ "../editor/out/ckeditor").then(
        ({ default: ClassicEditor }) => {
          editors.forEach((el) => {
            const allowMedia = el.parentElement.dataset.editor.includes(
              "allow-media"
            );
            const allowHeadings = el.parentElement.dataset.editor.includes(
              "allow-headings"
            );
            let toolbarItems = [
              "bold",
              "italic",
              "|",
              "bulletedList",
              "numberedList",
              "|",
              "link",
              "blockQuote",
            ];
            if (allowHeadings) {
              toolbarItems.unshift("heading", "|");
            }
            if (allowMedia) {
              toolbarItems.push("|", "insertImage", "mediaEmbed");
            }
            el.removeAttribute("required");
            ClassicEditor.create(el, {
              image: {
                toolbar: [
                  "imageStyle:alignLeft",
                  "imageStyle:full",
                  "imageStyle:alignCenter",
                  "imageStyle:alignRight",
                  "linkImage",
                ],
                styles: ["full", "alignLeft", "alignCenter", "alignRight"],
              },
              toolbar: toolbarItems,
            }).then((editor) => {
              editor.isReadOnly = el.hasAttribute("disabled");
            });
          });
        }
      );
    }
  }
}

export default Wysiwyg;
