import Glide from "@glidejs/glide";

import "@glidejs/glide/dist/css/glide.core.css";

function bound(value, low, high) {
  let diff = high - low;
  return ((value - low) % diff) + low;
}

class Slider {
  constructor() {
    this.init();
  }

  init() {
    const sliders = document.querySelectorAll(".glide");
    if (!sliders) {
      return;
    }
    sliders.forEach(slider => {
      const slides = slider.querySelectorAll(".glide__slide");
      if (!slides) {
        return;
      }
      const glide = new Glide(slider, {
        type: "sliderWrap" in slider.dataset ? "carousel" : "slider",
        startAt: 0,
        perView: 6,
        peek: 100,
        autoplay: "sliderAutoplay" in slider.dataset ? 3000 : 0,
        hoverpause: true,
        touchRatio: 1,
        breakpoints: {
          500: { perView: 1 },
          700: { perView: 2 },
          900: { perView: 3 },
          1200: { perView: 4 }
        }
      }).mount();
    });
  }
}

export default Slider;
