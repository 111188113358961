class ContactSelect {
    constructor() {
        this.contactSelect = document.querySelector("[data-contact-select]");
        if (!this.contactSelect) {
            return;
        }
        this.contactSubject =
            this.contactSelect.querySelector("[name=subject]");
        this.contactSubjectDetails = this.contactSelect.querySelector(
            "[data-contact-subject-details]"
        );
        this.subjectLabels = this.contactSelect.querySelectorAll(
            "[data-contact-subject-label]"
        );
        this.subjectContacts = this.contactSelect.querySelectorAll(
            "[data-contact-subject-contact]"
        );
        this.subjectSpecifics = this.contactSelect.querySelectorAll(
            "[data-contact-subject]"
        );
        this.contactForm = document.querySelector("[data-contact-methods]");
        //this.contactShow = document.querySelector("[data-contact-show]");
        this.init();
    }

    init() {
        //this.contactForm.classList.add("mod-hidden");
        //this.contactShow.addEventListener("click", () => {
        //  this.contactForm.classList.remove("mod-hidden");
        //});
        this.contactSubject.addEventListener(
            "change",
            this.onSubjectChange.bind(this)
        );
        this.onSubjectChange();
    }

    onSubjectChange(ev) {
        const selectedSubject =
            this.contactSubject.options[this.contactSubject.selectedIndex];
        if (selectedSubject.value) {
            this.contactSubjectDetails.classList.remove("mod-hidden");
            this.subjectLabels.forEach((el) => {
                el.innerText = selectedSubject.text;
            });
        } else {
            this.contactSubjectDetails.classList.add("mod-hidden");
        }
        this.subjectSpecifics.forEach((el) => {
            if (el.dataset.contactSubject === selectedSubject.value) {
                el.classList.remove("mod-hidden");
            } else {
                el.classList.add("mod-hidden");
            }
        });
        this.subjectContacts.forEach((el) => {
            const subjects = el.dataset.contactSubjectContact.split(",");
            if (subjects.includes(this.contactSubject.value)) {
                el.classList.remove("mod-hidden");
            } else {
                el.classList.add("mod-hidden");
            }
        });
    }
}

export default ContactSelect;
