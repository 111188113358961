import React from "react";
import { createRoot } from "react-dom/client";

class ExpoMap {
  constructor() {
    this.init();
  }

  init() {
    const expoMap = document.querySelector(".expo-map");
    if (!expoMap) {
      return;
    }
    Promise.all([
      import(/* webpackChunkName: "expo-map" */ "../react/ExpoMap/MapWrapper"),
      import(/* webpackChunkName: "expo-map" */ "easy-peasy"),
    ]).then(
      ([
        { default: MapWrapper },
        { createStore, StoreProvider, action, thunk },
      ]) => {
        const store = createStore({
          maps: [],
          stands: [],
          exhibitors: [],
          updateMaps: action((state, payload) => {
            return {
              ...state,
              maps: payload.maps,
              stands: payload.stands,
              exhibitors: payload.exhibitors,
            };
          }),
        });

        const root = createRoot(expoMap);

        root.render(
          <StoreProvider store={store}>
            <MapWrapper />
          </StoreProvider>
        );
      }
    );
  }
}

export default ExpoMap;
