class MeetingRoom {
  constructor() {
    this.init();
  }

  init() {
    const domain = "meet.jit.si";
    this.rooms = document.querySelectorAll("[data-meeting-room]");
    this.rooms.forEach((room) => {
      const options = {
        roomName: room.dataset.meetingRoom,
        width: "100%",
        height: 700,
        parentNode: room,
      };
      const api = new JitsiMeetExternalAPI(domain, options);
    });
  }
}

export default MeetingRoom;
