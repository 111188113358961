class Accordion {
  constructor() {
    this.init();
  }

  toggleBody(e) {
    const wrapper = e.target.parentNode;
    wrapper.classList.toggle("is-active");
  }

  init() {
    this.accordions = document.querySelectorAll("[data-accordion]");
    this.accordions.forEach((accordion) => {
      const toggles = accordion.querySelectorAll("[data-accordion-toggle]");
      toggles.forEach((toggle) => {
        const wrapper = toggle.parentNode;
        toggle.removeEventListener("click", this.toggleBody);
        toggle.addEventListener("click", this.toggleBody);
      });
      if (toggles.length === 1) {
        var event = new Event("click");
        toggles[0].dispatchEvent(event);
      }
    });
  }
}

export default Accordion;
