import React, { useState, useEffect, useRef, useCallback } from "react";

function columnToLetter(column) {
    let temp,
        letter = "";
    while (column > 0) {
        temp = (column - 1) % 26;
        letter = String.fromCharCode(temp + 65) + letter;
        column = (column - temp - 1) / 26;
    }
    return letter;
}

function SocketGrid({ breadth, depth, savedSockets, walls }) {
    const [purchasedSockets, setPurchasedSockets] = useState(0);
    const [sockets, setSockets] = useState({});

    const usedSockets = Object.keys(sockets).reduce(
        (acc, s) => acc + sockets[s],
        0
    );
    const socketCount = document.querySelector("[data-socket-count] input");
    const button = socketCount
        .closest("form")
        .querySelector("button[type='submit']");

    const handleSocketCountChange = (value) => {
        setPurchasedSockets(value);
        if (button) {
            if (usedSockets > value) {
                button.disabled = true;
            } else {
                button.disabled = false;
            }
        }
    };

    const handleSocketCountCallback = useCallback(
        (e) => handleSocketCountChange(e.target.value),
        [sockets]
    );

    useEffect(() => {
        handleSocketCountChange(socketCount.value);
        socketCount.addEventListener("change", handleSocketCountCallback);

        return () => {
            socketCount.removeEventListener("change", handleSocketCountCallback);
        };
    }, [handleSocketCountChange]);

    useEffect(() => {
        let newSockets = {};
        savedSockets.forEach((socket) => {
            if (socket in newSockets) {
                newSockets[socket]++;
            } else {
                newSockets[socket] = 1;
            }
        });
        setSockets(newSockets);
    }, []);

    const width = parseInt(breadth);
    const height = parseInt(depth);

    const cellClicked = (x, y) => {
        let newSockets = { ...sockets };
        const key = `${columnToLetter(x + 1)}${y + 1}`;
        if (key in newSockets) {
            delete newSockets[key];
            setSockets(newSockets);
        } else {
            newSockets[key] = 1;
        }

        let used = Object.keys(newSockets).reduce(
            (acc, s) => acc + newSockets[s],
            0
        );
        if (used <= purchasedSockets) {
            setSockets(newSockets);
        }
    };

    const setSocketCount = (key, count) => {
        let newSockets = { ...sockets };
        if (count <= 0) {
            delete newSockets[key];
            setSockets(newSockets);
        } else {
            newSockets[key] = count;
        }

        let used = Object.keys(newSockets).reduce(
            (acc, s) => acc + newSockets[s],
            0
        );
        if (used <= purchasedSockets) {
            setSockets(newSockets);
        }
    };

    return (
        <>
            <p
                className={
                    "mod-margin-bottom typography-text--status-" +
                    (usedSockets >= purchasedSockets ? "negative" : "neutral")
                }
            >
                <strong>
                    You have used {usedSockets} of {purchasedSockets} available socket
                    placements.
                </strong>
            </p>
            <div className="checkbox-grid">
                <div className="checkbox-grid__inner">
                    <div className="flex-grid checkbox-grid__table">
                        <div className="checkbox-grid__label">
                            <span>{walls[0]}</span>
                        </div>
                        {[...Array(height)].map((e, y) => (
                            <div className="checkbox-grid__row flex-grid__row" key={y}>
                                {[...Array(width)].map((e, x) => {
                                    const key = `${columnToLetter(x + 1)}${y + 1}`;
                                    const count = key in sockets ? sockets[key] : "";
                                    return (
                                        <div
                                            key={key}
                                            onClick={() => cellClicked(x, y)}
                                            className={
                                                "checkbox-grid__col " + (count > 0 ? "is-active" : "")
                                            }
                                        >
                                            <div className="checkbox-grid__count">{count}</div>
                                            <div className="checkbox-grid__cell-name">{key}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        ))}
                        <div className="checkbox-grid__label">
                            <span>{walls[2]}</span>
                        </div>
                        <div className="checkbox-grid__label">
                            <span>
                                Main Entrance
                                <i className="fas fa-chevron-left fa-rotate-270"></i>
                            </span>
                        </div>
                        <div className="checkbox-grid__label--vertical-left">
                            <span>{walls[3]}</span>
                        </div>
                        <div className="checkbox-grid__label--vertical-right">
                            <span>{walls[1]}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-grid card-grid--small">
                {Object.keys(sockets)
                    .sort()
                    .map((key) => (
                        <div className="form__field anim-highlight-on-load" key={key}>
                            <label className="form__label">Position {key}</label>
                            <div className="form__input--numeric">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setSocketCount(key, sockets[key] - 1);
                                    }}
                                >
                                    -
                                </button>
                                <input
                                    type="number"
                                    name={"socket-" + key}
                                    value={sockets[key]}
                                    onChange={(e) => setSocketCount(key, e.target.value)}
                                ></input>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setSocketCount(key, sockets[key] + 1);
                                    }}
                                >
                                    +
                                </button>
                            </div>
                        </div>
                    ))}
            </div>
        </>
    );
}

export { SocketGrid };
