import Emitter from "component-emitter";

class AccountStatus {
    /*
      Updates the header to indicate that the user is logged in, using an API endpoint that returns
      a bit of JSON containing the login status.
      We do this so we can still do full-page caching on common pages, even when the user is logged in.
    */
    constructor() {
        this.statusContainers = document.querySelectorAll("[data-account-name]");
        this.init();
    }

    updateAccountNames(name) {
        this.statusContainers.forEach((el) => {
            el.textContent = name;
        });
    }

    hideLoggedOutElements() {
        const els = document.querySelectorAll("[data-delete-if-logged-in]");
        els.forEach((el) => {
            el.remove();
        });
    }

    loadChatWidget(data) {
        window.Tawk_API = window.Tawk_API || {};
        window.Tawk_LoadStart = new Date();
        if (data.authenticated) {
            window.Tawk_API.visitor = {
                name: data.name,
                email: data.email,
            };
        }
        (function () {
            var s1 = document.createElement("script"),
                s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            s1.src = "https://embed.tawk.to/5ebd6d86967ae56c5219d2d7/default";
            s1.charset = "UTF-8";
            s1.setAttribute("crossorigin", "*");
            s0.parentNode.insertBefore(s1, s0);
        })();
    }

    init() {
        const updateAccountNames = this.updateAccountNames.bind(this);
        fetch("/accounts/status/", { credentials: "same-origin" })
            .then((res) => res.json())
            .then((data) => {
                if (data.authenticated) {
                    this.emit("authenticated", data);
                    updateAccountNames(data.name || "My Account");
                    this.hideLoggedOutElements();
                    if (data.admin) {
                        const userBar = document.querySelector(".wagtail-userbar");
                        if (userBar) {
                            userBar.style.setProperty("display", "block", "important");
                        }
                    }
                }
            });
    }
}

Emitter(AccountStatus.prototype);

export default AccountStatus;
