import React from "react";
import { createRoot } from 'react-dom/client';
import { SocketGrid as ReactSocketGrid } from "../react/SocketGrid";
import { createStore, StoreProvider, action, thunk } from "easy-peasy";

class SocketGrid {
    constructor() {
        this.init();
    }

    init() {
        const socketGrid = document.querySelector("[data-socket-grid]");

        if (!socketGrid) {
            return;
        }
        const socketGridConfig = JSON.parse(socketGrid.dataset.socketGrid);

        const store = createStore({});

        store.subscribe(() => { });

        const root = createRoot(socketGrid);

        root.render(
            <StoreProvider store={store}>
                <ReactSocketGrid
                    breadth={socketGridConfig.breadth}
                    depth={socketGridConfig.depth}
                    savedSockets={socketGridConfig.sockets}
                    walls={socketGridConfig.walls}
                />
            </StoreProvider>
        );
    }
}

export default SocketGrid;
