class StockUpdate {
  constructor() {
    this.init();
  }

  init() {
    const productStock = document.querySelectorAll("[data-product-stock]");
    if (productStock.length == 0) {
      return;
    }
    let productIds = [];

    productStock.forEach((el) => {
      productIds.push(el.dataset.productStock);
    });

    let productQueryString = productIds.join(",");

    fetch("/shop/api/stock/?products=" + productQueryString, {
      credentials: "same-origin",
    })
      .then((res) => res.json())
      .then((data) => {
        productStock.forEach((el) => {
          const stock = data[el.dataset.productStock];
          el.textContent = stock;
        });
      });
  }
}

export default StockUpdate;
