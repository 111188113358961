class RandomSponsor {
  /*
  Randomises the sponsor shown in sponsor blocks
  */
  constructor() {
    this.sponsorBlocks = document.querySelectorAll("[data-random-sponsor]");
    this.init();
  }

  randomiseSponsors(target) {
    // Skip randomising if we're not targetting an element with a sponsor in it
    if (target && !target.querySelector("[data-random-sponsor]")) {
      return;
    }
    this.sponsorBlocks.forEach((block) => {
      const sponsors = block.children;
      if (sponsors.length == 0) {
        return;
      }
      for (let el of sponsors) {
        el.classList.add("mod-hidden");
      }
      const random = Math.floor(Math.random() * sponsors.length);
      sponsors[random].classList.remove("mod-hidden");
    });
  }

  init() {
    this.randomiseSponsors();
  }
}

export default RandomSponsor;
