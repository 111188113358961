class Twitch {
  constructor() {
    this.init();
  }

  init() {
    const embeds = document.querySelectorAll("[data-twitch-embed]");
    if (embeds.length === 0) {
      return;
    }
    embeds.forEach((embed) => {
      const channel = embed.dataset.twitchEmbed.split("/")[3];
      console.log(channel);
      new global.Twitch.Embed(embed, {
        width: "100%",
        height: "100%",
        channel: channel,
      });
    });
  }
}

export default Twitch;
