const UPDATE_STATES = {
  STOP_UPDATING: 1,
  UPDATE_EVERY_MINUTE: 2,
  UPDATE_EVERY_SECOND: 3,
};

class Countdown {
  constructor() {
    this.init();
  }

  updateCountdown(item) {
    const oneDay = 24 * 60 * 60 * 1000;
    const targetDate = new Date(item.dataset.countdownTo * 1000);
    const container = item;
    const now = Date.now();
    const differenceMs = targetDate - now;
    const differenceDate = new Date(targetDate - now);

    const days = Math.round(Math.abs(differenceMs) / oneDay);
    const hours = differenceDate.getHours();
    const minutes = (differenceDate.getMinutes() + 100).toString().substring(1);
    const seconds = (differenceDate.getSeconds() + 100).toString().substring(1);

    const expoYear = targetDate.getFullYear();

    if (differenceMs < 0) {
      container.innerHTML = `Expo ${expoYear} is happening now!`;
      return UPDATE_STATES.STOP_UPDATING;
    } else {
      if (days > 30) {
        container.innerHTML = `${days} days to go`;
        return UPDATE_STATES.STOP_UPDATING;
      } else if (days > 1) {
        container.innerHTML = `${days - 1} days, ${hours}:${minutes} to go`;
        return UPDATE_STATES.UPDATE_EVERY_MINUTE;
      } else if (days > 0) {
        container.innerHTML = `${
          days - 1
        } day, ${hours}:${minutes}:${seconds} to go`;
        return UPDATE_STATES.UPDATE_EVERY_MINUTE;
      } else {
        container.innerHTML = `${hours}:${minutes}:${seconds} to go`;
        return UPDATE_STATES.UPDATE_EVERY_SECOND;
      }
      return true;
    }
  }

  init() {
    clearInterval(this.interval);

    this.countdowns = document.querySelectorAll("[data-countdown-to]");
    this.countdowns.forEach((item) => {
      const updateState = this.updateCountdown(item);
      if (updateState === UPDATE_STATES.UPDATE_EVERY_SECOND) {
        this.interval = setInterval(() => {
          this.updateCountdown(item);
        }, 1000);
      } else if (updateState === UPDATE_STATES.UPDATE_EVERY_MINUTE) {
        this.interval = setInterval(() => {
          this.updateCountdown(item);
        }, 60000);
      }
    });
  }
}

export default Countdown;
