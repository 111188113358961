import Emitter from "component-emitter";

class MobileMenu {
  constructor() {
    this.menu = document.querySelector("#mobile-menu");
    this.toggle = document.querySelector("[data-mobile-menu-toggle]");
    this.userMenu = document.querySelector("#mobile-user-menu");
    this.userToggle = document.querySelector("[data-mobile-user-menu-toggle]");
    this.userMenuLoaded = false;
    this.userAuthenticated = false;
    this.init();
  }

  setUserAuthenticated() {
    this.userAuthenticated = true;
  }

  showMenu() {
    this.hideUserMenu();
    this.menu.classList.add("is-active");
    this.toggle.classList.add("is-active");
    this.emit("show");
  }

  hideMenu() {
    this.menu.classList.remove("is-active");
    this.toggle.classList.remove("is-active");
    this.emit("hide");
  }

  showUserMenu() {
    this.hideMenu();
    this.userMenu.classList.add("is-active");
    this.userToggle.classList.add("is-active");

    if (!this.userMenuLoaded && this.userAuthenticated) {
      fetch("/account/mobile-menu/", { credentials: "same-origin" })
        .then((res) => res.text())
        .then((data) => {
          let newMenu = document.createElement("div");
          newMenu.innerHTML = data;
          newMenu.firstElementChild.classList.add("is-active");
          this.userMenu.parentNode.replaceChild(
            newMenu.firstElementChild,
            this.userMenu
          );
          this.userMenu = document.querySelector("#mobile-user-menu");
          this.userMenuLoaded = true;
          this.emit("show");
        });
    } else {
      this.emit("show");
    }
  }

  hideUserMenu() {
    this.userMenu.classList.remove("is-active");
    this.userToggle.classList.remove("is-active");
    this.emit("hide");
  }

  init() {
    if (!this.menu) {
      return;
    }
    this.toggle.addEventListener("click", (event) => {
      if (this.menu.classList.contains("is-active")) {
        this.hideMenu();
      } else {
        this.showMenu();
      }
      event.preventDefault();
    });

    this.userToggle.addEventListener("click", (event) => {
      if (this.userAuthenticated) {
        if (this.userMenu.classList.contains("is-active")) {
          this.hideUserMenu();
        } else {
          this.showUserMenu();
        }
        event.preventDefault();
      }
    });

    const items = this.menu.querySelectorAll("li");

    items.forEach((item) => {
      const link = item.querySelector("a");
      const childPage = item.querySelector("ul");

      if (childPage) {
        link.setAttribute("href", "#");
        link.addEventListener("click", (event) => {
          childPage.classList.add("is-active");
          event.preventDefault();
        });

        const upLink = childPage.querySelector("[data-action=up-menu]");
        upLink.addEventListener("click", (event) => {
          childPage.classList.remove("is-active");
          event.preventDefault();
        });
      }
    });
  }
}

Emitter(MobileMenu.prototype);

export default MobileMenu;
