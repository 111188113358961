class AddToSchedule {
    init() {
        this.addToSchedule = document.querySelectorAll("[data-add-to-schedule]");
        if (this.addToSchedule.length === 0) {
            return;
        }
        if (!document.registrationData) {
            this.fetchRegistrations();
            return;
        }
        if (!document.registrationData.registrations) {
            return;
        }
        self.data = document.registrationData;
        this.addToSchedule.forEach((item) => {
            const radiosInParentForm = item
                .closest("form")
                .querySelectorAll("input[name='item_id']");
            const selectedItem = item
                .closest("form")
                .querySelector("input[name='item_id'][checked]");
            this.updateButton(item, selectedItem.value);
            radiosInParentForm.forEach((radio) => {
                radio.addEventListener("change", (e) => {
                    this.updateButton(item, radio.value);
                });
            });
        });
    }

    fetchRegistrations() {
        fetch("/accounts/status/registrations/", { credentials: "same-origin" })
            .then((res) => res.json())
            .then((data) => {
                document.registrationData = data;
                this.init();
            });
    }

    updateButton(element, selectedId) {
        if (self.data.registrations.includes(selectedId)) {
            element.querySelector("button").innerHTML = "Remove from My Schedule";
            element.querySelector("button").classList.add("button--secondary");
            element.querySelector("[name=register]").value = "";
        } else {
            element.querySelector("button").innerHTML = "Add To My Schedule";
            element.querySelector("button").classList.remove("button--secondary");
            element.querySelector("[name=register]").value = "1";
        }
    }
}

export default AddToSchedule;
