import "nouislider/dist/nouislider.css";

class RangeSlider {
  constructor() {
    this.init();
  }

  init() {
    const formatter = {
      to: (value) => {
        let hours = Math.floor(value / 60);
        let minutes = Math.floor(value) - hours * 60;
        if (hours.toString().length == 1) hours = "0" + hours;
        if (minutes.toString().length == 1) minutes = "0" + minutes;
        return `${hours}:${minutes}`;
      },
      from: (value) => {
        let [hours, minutes] = value.split(":");
        return parseInt(hours) * 60 + parseInt(minutes);
      },
    };
    const sliders = document.querySelectorAll("[data-range-slider]");
    if (sliders.length > 0) {
      import(/* webpackChunkName: "rangeslider" */ "nouislider").then(
        ({ default: noUiSlider }) => {
          sliders.forEach((slider) => {
            let sliderEl = slider.querySelector("div");
            let inputs = [];
            inputs[0] = slider.querySelector("input:first-of-type");
            inputs[1] = slider.querySelector("input:last-of-type");
            noUiSlider.create(sliderEl, {
              step: 60,
              start: [parseInt(inputs[0].value), parseInt(inputs[1].value)],
              connect: true,
              tooltips: [formatter, formatter],
              range: {
                min: 0,
                max: 1440,
              },
            });
            sliderEl.noUiSlider.on("update", (values, handle) => {
              inputs[handle].value = Math.floor(values[handle]);
            });
            inputs[0].addEventListener("change", (ev) => {
              sliderEl.noUiSlider.set([ev.target.value, null]);
            });
            inputs[1].addEventListener("change", (ev) => {
              sliderEl.noUiSlider.set([null, ev.target.value]);
            });
          });
        }
      );
    }
  }
}

export default RangeSlider;
