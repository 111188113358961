import Emitter from "component-emitter";

class Repeatable {
  /*
  Element which can have new rows added and removed dynamically
  */
  constructor() {
    this.repeatables = document.querySelectorAll("[data-repeatable]");
    this.init();
  }

  createElement(repeatable) {
    const template = repeatable.querySelector("[data-repeatable-template]");
    const container = repeatable.querySelector("[data-repeatable-container]");
    const totalForms = repeatable
      .closest("form")
      .querySelector("[name=form-TOTAL_FORMS]");
    let elementIdx = totalForms.value;
    totalForms.value = parseInt(totalForms.value) + 1;

    const clone = template.content.cloneNode(true);
    const newEl = Array.from(clone.children)[0];
    container.appendChild(clone);
    newEl.innerHTML = newEl.innerHTML.replaceAll(
      "__prefix__",
      `form-${elementIdx}`
    );
    const deleteButton = newEl.querySelector("[data-repeatable-delete]");
    this.bindDeleteButton(deleteButton);
    this.emit("create");
  }

  deleteElement(element) {
    const totalForms = element
      .closest("form")
      .querySelector("[name=form-TOTAL_FORMS]");
    element.parentNode.removeChild(element);
    totalForms.value = parseInt(totalForms.value) - 1;
  }

  bindDeleteButton(button) {
    button.addEventListener("click", (ev) => {
      this.deleteElement(button.parentNode);
      ev.preventDefault();
    });
  }

  init() {
    this.repeatables.forEach((repeatable) => {
      const addButton = repeatable.querySelector("[data-repeatable-add]");

      const existingDeleteButtons = repeatable.querySelectorAll(
        "[data-repeatable-delete]"
      );
      existingDeleteButtons.forEach((button) => {
        this.bindDeleteButton(button);
      });

      addButton.addEventListener("click", (ev) => {
        this.createElement(repeatable);

        ev.preventDefault();
      });
    });
  }
}
Emitter(Repeatable.prototype);

export default Repeatable;
