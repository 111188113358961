import React, { useState, useEffect, useCallback } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import debounce from "lodash/debounce";

function DeleteModal(props) {
    const deleteItemId = useStoreState((state) => state.deleteItemId);
    const updateDeleteItemId = useStoreActions(
        (actions) => actions.updateDeleteItemId
    );
    const updateItemQuantity = useStoreActions(
        (actions) => actions.updateItemQuantity
    );
    const [classNames, setClassNames] = useState("modal");

    useEffect(() => {
        if (deleteItemId) {
            setClassNames("modal is-active");
        } else {
            setClassNames("modal");
        }
    }, [deleteItemId]);

    const handleDeleteItem = useCallback(() => {
        updateItemQuantity({ itemId: deleteItemId, quantity: 0 });
        updateDeleteItemId(null);
    });

    const handleCancel = useCallback(() => {
        updateItemQuantity({ itemId: deleteItemId, quantity: 1 });
        updateDeleteItemId(null);
    });

    return (
        <div className={classNames}>
            <h1>Are you sure?</h1>
            <p className="mod-margin-bottom">
                This will remove this item from your basket.
            </p>
            <div
                className="button--secondary"
                onClick={() => handleDeleteItem()}
            >
                Remove Item
            </div>
            <div
                className="button--subtle"
                onClick={() => {
                    handleCancel();
                }}
            >
                Cancel
            </div>
        </div>
    );
}

function LineItem(props) {
    const [pendingQuantity, setPendingQuantity] = useState(props.quantity);

    useEffect(() => {
        if (
            props.max_quantity &&
            props.max_quantity !== -1 &&
            pendingQuantity > props.max_quantity
        ) {
            setPendingQuantity(props.max_quantity);
        }
        if (pendingQuantity != props.quantity) {
            props.updateItemQuantity(pendingQuantity);
        }
    }, [pendingQuantity]);

    useEffect(() => {
        setPendingQuantity(props.quantity);
    }, [props.quantity]);

    return (
        <li className="basket__item">
            <h3 className="basket__item-name">{props.name}</h3>
            <div className="basket__item-description">{props.description}</div>
            {!props.readOnly ? (
                <div className="basket__item-quantity form__field">
                    <div className="form__input--numeric">
                        <button
                            type="button"
                            onClick={() =>
                                setPendingQuantity(pendingQuantity - 1)
                            }
                            disabled={pendingQuantity === 0}
                        >
                            -
                        </button>
                        <input
                            type="number"
                            name="quantity"
                            value={pendingQuantity}
                            onChange={(e) =>
                                setPendingQuantity(parseInt(e.target.value))
                            }
                            min={1}
                            max={200}
                        ></input>
                        <button
                            type="button"
                            onClick={() =>
                                setPendingQuantity(pendingQuantity + 1)
                            }
                            disabled={
                                props.max_quantity && props.max_quantity !== -1
                                    ? pendingQuantity + 1 > props.max_quantity
                                    : false
                            }
                        >
                            +
                        </button>
                    </div>
                </div>
            ) : null}
            <div className="basket__item-price">{props.formatted_price}</div>
            {!props.readOnly ? (
                <button
                    className="basket__item-remove"
                    onClick={() => setPendingQuantity(0)}
                >
                    ✕
                </button>
            ) : null}
        </li>
    );
}

function Basket(props) {
    const basket = useStoreState((state) => state.basket);
    const updateBasket = useStoreActions((actions) => actions.updateBasket);
    const updateItemQuantityAction = useStoreActions(
        (actions) => actions.updateItemQuantity
    );
    const updateDeleteItemId = useStoreActions(
        (actions) => actions.updateDeleteItemId
    );

    useEffect(() => {
        const res = fetch("/shop/api/basket/", { credentials: "same-origin" });
        res.then((res) => res.json()).then((res) => updateBasket(res));
    }, []);

    const updateItemQuantity = useCallback((itemId, quantity) => {
        if (quantity <= 0) {
            updateDeleteItemId(itemId);
        } else {
            updateItemQuantityDebounced(itemId, quantity);
        }
    });

    const updateItemQuantityDebounced = useCallback(
        debounce((itemId, quantity) => {
            updateItemQuantityAction({ itemId, quantity });
        }, 300)
    );

    return (
        <>
            <ul className="basket__items">
                {basket.items.map((item) => (
                    <LineItem
                        key={item.item_id}
                        readOnly={props.readOnly}
                        updateItemQuantity={(quantity) =>
                            updateItemQuantity(item.item_id, quantity)
                        }
                        {...item}
                    />
                ))}
            </ul>
            <div className="basket__totals">
                {basket.shipping_total > 0 ? (
                    <div className="basket__total">
                        <span className="basket__total-label">
                            Delivery Cost
                        </span>
                        <span className="basket__total-value" data-basket-total>
                            {basket.formatted_shipping_total}
                        </span>
                    </div>
                ) : null}
                <div className="basket__total--final">
                    <span className="basket__total-label">Basket Total</span>
                    <span className="basket__total-value" data-basket-total>
                        {basket.formatted_total}
                    </span>
                </div>
            </div>
        </>
    );
}

export { Basket, DeleteModal };
