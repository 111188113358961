class ExhibitorChecklist extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.updateProgressBar();

        this.querySelectorAll("input[type=checkbox]").forEach((checkbox) => {
            checkbox.addEventListener("change", () => {
                this.updateProgressBar();
            });
        });
    }

    updateProgressBar() {
        const progressBar = this.querySelector("[data-progress-bar]");
        const checkboxes = this.querySelectorAll("input[type=checkbox]");
        const checkedCheckboxes = this.querySelectorAll(
            "input[type=checkbox]:checked"
        );
        const checkedCount = checkedCheckboxes.length;
        const totalCount = checkboxes.length;
        const percent = Math.round((checkedCount / totalCount) * 100);
        if (percent < 2) {
            progressBar.style.width = "2%";
        } else {
            progressBar.style.width = `${percent}%`;
        }
    }
}

customElements.define("exhibitor-checklist", ExhibitorChecklist);
export default ExhibitorChecklist;
