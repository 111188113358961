class TableCalculation {
  constructor() {
    this.init();
  }

  init() {
    const multipliers = document.querySelectorAll("[data-table-multiply]");
    this.totals = document.querySelectorAll("[data-table-total]");
    if (multipliers) {
      this.bindMultipliers(multipliers);
    }
  }

  bindMultipliers(multipliers) {
    multipliers.forEach(el => {
      const input = el.parentElement.parentElement.querySelector("input");
      if (input.type == "number") {
        input.addEventListener("change", ev => {
          this.updateMultiplier(el, parseInt(input.value));
        });
        this.updateMultiplier(el, parseInt(input.value));
      } else {
        input.parentElement
          .querySelector("[data-calc-anchor]")
          .addEventListener("click", ev => {
            const checked = el.parentElement.parentElement.querySelectorAll(
              "input:checked"
            );
            this.updateMultiplier(el, checked.length);
          });
        const checked = el.parentElement.parentElement.querySelectorAll(
          "input:checked"
        );
        this.updateMultiplier(el, parseInt(checked.length));
      }
    });
  }

  updateMultiplier(el, multiplyBy) {
    const baseValue = parseFloat(el.dataset.tableMultiply);
    let total = multiplyBy * baseValue;
    el.dataset.total = total;
    el.innerHTML = total.toFixed(2);
    this.updateTotals();
  }

  updateTotals() {
    this.totals.forEach(el => {
      const values = el.closest("form").querySelectorAll("[data-total]");
      let total = 0;
      values.forEach(el => {
        total += parseFloat(el.dataset.total);
      });
      el.innerHTML = total.toFixed(2);
    });
  }
}

export default TableCalculation;
