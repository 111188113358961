class Collapsible {
  constructor() {
    this.init();
  }

  init() {
    const collapsibles = document.querySelectorAll("[data-collapsible]");
    collapsibles.forEach((collapsible) => {
      const buttons = collapsible.querySelectorAll("[data-collapsible-button]");
      if (buttons.length == 0) {
        return;
      }
      const target = collapsible.querySelector("[data-collapsible-target]");
      buttons.forEach((button) => {
        button.addEventListener("click", (ev) => {
          if (buttons.length > 1)
            if (target.classList.contains("mod-hidden")) {
              buttons[0].classList.remove("mod-hidden");
              buttons[1].classList.add("mod-hidden");
            } else {
              buttons[0].classList.add("mod-hidden");
              buttons[1].classList.remove("mod-hidden");
            }
          target.classList.toggle("mod-hidden");
        });
      });
    });
  }
}

export default Collapsible;
