// Polyfills
import "whatwg-fetch";

// Third-party
import htmx from "htmx.org";
window.htmx = htmx;
import "idiomorph";

import { refresh as refreshIcons } from "./components/icons";
import DesktopMenu from "./components/desktop-menu";
import MobileMenu from "./components/mobile-menu";
import Forms from "./components/forms";
import Notifications from "./components/notifications";
import Countdown from "./components/countdown";
import AccountStatus from "./components/account-status";
import Search from "./components/search";
import Accordion from "./components/accordion";
import Filters from "./components/filters";
import Wizard from "./components/wizard";
import Basket from "./components/basket";
import Wysiwyg from "./components/wysiwyg";
import RandomSponsor from "./components/random-sponsor";
import TableOfContents from "./components/table-of-contents";
import RangeSlider from "./components/range-slider";
import IntegerSlider from "./components/integer-slider";
import ToggleBox from "./components/toggle-box";
import TableCalculation from "./components/table-calculation";
import PaymentPage from "./components/payment-page";
import Confirm from "./components/confirm";
import RandomBg from "./components/random-bg";
import RandomiseList from "./components/randomise-list";
import ConditionalVisible from "./components/conditional-visible";
import ContactSelect from "./components/contact-select";
import StockUpdate from "./components/stock-update";
import SocketGrid from "./components/socket-grid";
import ExpoMap from "./components/expo-map";
import Repeatable from "./components/repeatable";
import Collapsible from "./components/collapsible";
import Tabbed from "./components/tabbed";
import Schedule from "./components/schedule";
import PWAApp from "./components/pwa-app";
import Twitch from "./components/twitch";
import MeetingRoom from "./components/meeting-room";
import AddToSchedule from "./components/addtoschedule";
import DynamicFormset from "./components/dynamic-formset";
import LocationPicker from "./components/location-picker";
import CommunityEventMap from "./components/community-event-map";
import EventRPGTimeFilter from "./components/event-rpg-time-filter";
import ReactSelectMounter from "./components/react-select";
import Slider from "./components/slider";
import ExhibitorChecklist from "./components/exhibitor-checklist";
import EventSubmissionTools from "./components/event-submission-tools";
import DependentDropdowns from "./components/dependent-dropdowns";

document.addEventListener("DOMContentLoaded", function () {
    const accountStatus = new AccountStatus();
    const search = new Search();
    const desktopMenu = new DesktopMenu();
    const mobileMenu = new MobileMenu();
    const forms = new Forms();
    const notifications = new Notifications();
    const twitch = new Twitch();
    const countdown = new Countdown();
    const accordion = new Accordion();
    const filters = new Filters();
    const wizard = new Wizard();
    const basket = new Basket();
    const wysiwyg = new Wysiwyg();
    const randomSponsor = new RandomSponsor();
    const tableOfContents = new TableOfContents();
    const rangeSlider = new RangeSlider();
    const integerSlider = new IntegerSlider();
    const toggleBox = new ToggleBox();
    const tableCalculation = new TableCalculation();
    const confirm = new Confirm();
    const randomBg = new RandomBg();
    const randomiseList = new RandomiseList();
    const conditionalVisible = new ConditionalVisible();
    const contactSelect = new ContactSelect();
    const stockUpdate = new StockUpdate();
    const socketGrid = new SocketGrid();
    const expoMap = new ExpoMap();
    const repeatable = new Repeatable();
    const collapsible = new Collapsible();
    const tabbed = new Tabbed();
    const schedule = new Schedule();
    const meetingRoom = new MeetingRoom();
    const addToSchedule = new AddToSchedule();
    const dynamicFormset = new DynamicFormset();
    const locationPicker = new LocationPicker();
    const communityEventMap = new CommunityEventMap();
    const eventRPGTimeFilter = new EventRPGTimeFilter();
    const reactSelectMounter = new ReactSelectMounter();
    const slider = new Slider();
    const eventSubmissionTools = new EventSubmissionTools();
    const dependentDropdowns = new DependentDropdowns();

    global.paymentPage = new PaymentPage();
    refreshIcons();

    desktopMenu.on("show", (ev) => {
        randomSponsor.randomiseSponsors(ev.target);
    });

    mobileMenu.on("hide", () => {
        search.hide();
    });

    mobileMenu.on("show", () => {
        refreshIcons();
    });

    repeatable.on("create", () => {
        refreshIcons();
    });

    dynamicFormset.on("change", () => {
        conditionalVisible.init();
        eventRPGTimeFilter.setInitial();
        reactSelectMounter.init();
    });

    accountStatus.on("authenticated", (data) => {
        mobileMenu.setUserAuthenticated();
        addToSchedule.init();
    });

    basket.on("show", () => {
        search.hide();
    });

    search.on("show", () => {
        basket.hide();
    });

    tabbed.on("toggle", () => {
        schedule.initUpcomingSchedules();
    });

    function reload() {
        forms.init();
        notifications.init();
        countdown.init();
        accordion.init();
        filters.init();
        wizard.init();
        basket.init();
        wysiwyg.init();
        randomSponsor.init();
        expoMap.init();
        refreshIcons();
    }
    const pwaApp = new PWAApp({ mobileMenu, desktopMenu, search }, reload);
});
