import React from "react";
import { createRoot } from "react-dom/client";

class CommunityEventMap {
    constructor() {
        this.init();
    }

    init() {
        const maps = document.querySelectorAll("[data-community-map]");
        if (!maps) {
            return;
        }
        Promise.all([
            import(
                /* webpackChunkName: "community-event-map" */ "../react/CommunityEventMap"
            ),
        ]).then(([{ default: CommunityEventMap }]) => {
            for (const map of maps) {
                const container = document.createElement("div");
                container.setAttribute("style", "height: 500px; width: 100%;");
                map.appendChild(container);
                const root = createRoot(container);
                const things = document.map_things;

                root.render(<CommunityEventMap things={things} />);
            }
        });
    }
}

export default CommunityEventMap;
