import "nouislider/dist/nouislider.css";

class IntegerSlider {
    constructor() {
        this.init();
    }

    init() {
        const formatter = {
            to: (value) => {
                return Math.round(value);
            },
            from: (value) => {
                return value;
            },
        };
        const sliders = document.querySelectorAll("[data-integer-slider]");
        if (sliders.length > 0) {
            import(/* webpackChunkName: "rangeslider" */ "nouislider").then(
                ({ default: noUiSlider }) => {
                    sliders.forEach((slider) => {
                        let sliderEl = slider.querySelector("div");
                        const input = slider.querySelector("input");
                        noUiSlider.create(sliderEl, {
                            step: 1,
                            start: parseInt(input.value),
                            connect: "lower",
                            decimals: 0,
                            tooltips: formatter,
                            range: {
                                min: 0,
                                max: parseInt(input.dataset.sliderMax) || 20,
                            },
                        });
                        sliderEl.noUiSlider.on("update", (values, handle) => {
                            input.value = Math.floor(values[handle]);
                        });
                        input.addEventListener("change", (ev) => {
                            sliderEl.noUiSlider.set([ev.target.value]);
                        });
                    });
                }
            );
        }
    }
}

export default IntegerSlider;
