class Filters {
  constructor() {
    this.init();
  }

  init() {
    this.filterBar = document.querySelector("[data-filter-bar]");
    this.clears = document.querySelectorAll("[data-clear]");
    this.selects = document.querySelectorAll("[data-select]");
    this.activeItem = null;
    if (!this.filterBar) {
      return;
    }
    this.initSpecialActions();
    const buttons = this.filterBar.querySelectorAll("[data-filter-button]");
    buttons.forEach((button) => {
      const wrapper = button.parentNode;
      button.addEventListener("click", (e) => {
        if (wrapper.classList.contains("is-active")) {
          wrapper.classList.remove("is-active");
          this.activeItem = null;
        } else {
          if (this.activeItem) {
            this.activeItem.classList.remove("is-active");
          }
          wrapper.classList.add("is-active");
          this.activeItem = wrapper;
        }
        e.preventDefault();
      });
    });
  }

  initSpecialActions() {
    this.clears.forEach((el) => {
      el.addEventListener("click", (e) => {
        const form = el.closest("form");
        if (el.dataset.clear === "all") {
          form.querySelectorAll("[type=checkbox]").forEach((el) => {
            el.checked = false;
          });
          form.querySelectorAll("[data-default-value]").forEach((el) => {
            el.value = el.dataset.defaultValue;
            var event = new Event("change");
            el.dispatchEvent(event);
          });
        } else {
          form.querySelectorAll(`[name=${el.dataset.clear}]`).forEach((el) => {
            el.checked = false;
            el.value = "";
          });
        }
        e.preventDefault();
        if ("clearSubmit" in el.dataset) {
          form.submit();
        }
      });
    });
    this.selects.forEach((el) => {
      el.addEventListener("click", (e) => {
        if (el.dataset.select === "all") {
          el.closest("form")
            .querySelectorAll("[type=checkbox]")
            .forEach((el) => {
              el.checked = true;
            });
          el.closest("form")
            .querySelectorAll("[data-default-value]")
            .forEach((el) => {
              el.value = el.dataset.defaultValue;
              var event = new Event("change");
              el.dispatchEvent(event);
            });
        }
        e.preventDefault();
      });
    });
  }
}

export default Filters;
