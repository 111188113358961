import React from "react";
import { createRoot } from "react-dom/client";

class LocationPicker {
    constructor() {
        this.init();
    }

    init() {
        const pickers = document.querySelectorAll("[data-location-picker]");
        if (!pickers) {
            return;
        }
        Promise.all([
            import(
                /* webpackChunkName: "location-picker" */ "../react/MapLocationPicker"
            ),
        ]).then(([{ default: MapLocationPicker }]) => {
            for (const picker of pickers) {
                const container = document.createElement("div");
                container.setAttribute("style", "height: 500px; width: 100%;");
                picker.parentNode.parentNode.appendChild(container);
                const root = createRoot(container);
                const location = picker.value ? picker.value.split(",") : null;
                picker.classList.add("mod-hidden");
                const addressFields =
                    picker.parentNode.parentNode.parentNode.parentNode.querySelectorAll(
                        "[data-location-picker-address]"
                    );

                // We store the location as [lng, lat] but the map wants [lat, lng]
                root.render(
                    <MapLocationPicker
                        addressFields={addressFields}
                        fieldValue={
                            location ? [location[1], location[0]] : null
                        }
                        setFieldValue={(location) => {
                            picker.value = location
                                ? `${location[1]},${location[0]}`
                                : "";
                        }}
                        type={picker.dataset.locationPicker}
                    />
                );
            }
        });
    }
}

export default LocationPicker;
