import debounce from "lodash/debounce";
import Emitter from "component-emitter";

class Search {
  constructor() {
    this.searchOverlay = document.querySelector(".search-overlay");
    this.searchToggle = document.querySelector("#search-toggle");
    this.searchBox = document.querySelector(".search-overlay__input input");
    this.searchResults = document.querySelector(".search-overlay__results");

    this.init();
  }

  renderResults(results) {
    const resultTemplate = ({ title, url, type, updated }) => {
      return `
        <li class="search-overlay__item">
            <a href="${url}">
               ${title}
               <span>${type}</span>
            </a>
        </li>
      `;
    };
    this.searchResults.classList.remove("mod-hidden");
    this.searchResults.innerHTML = results.reduce(
      (acc, currentValue) => acc + resultTemplate(currentValue),
      ""
    );
  }

  show() {
    this.searchOverlay.classList.add("is-active");
    this.emit("show");
    this.searchBox.focus();
  }

  hide() {
    this.searchOverlay.classList.remove("is-active");
  }

  init() {
    if (
      !this.searchOverlay ||
      !this.searchToggle ||
      !this.searchBox ||
      !this.searchResults
    ) {
      return;
    }
    this.searchToggle.addEventListener("click", (event) => {
      if (this.searchOverlay.classList.contains("is-active")) {
        this.hide();
      } else {
        this.show();
      }
      event.preventDefault();
      event.stopPropagation();
    });

    this.searchBox.addEventListener(
      "keyup",
      debounce((event) => {
        const request = new XMLHttpRequest();
        const query = encodeURIComponent(this.searchBox.value);

        request.open("GET", `/search-json/?query=${query}`, true);

        const renderResults = this.renderResults.bind(this);

        request.onload = function() {
          if (this.status >= 200 && this.status < 400) {
            const data = JSON.parse(this.response);
            renderResults(data.results);
          }
        };

        request.onerror = function() {};

        request.send();
      }, 500)
    );
  }
}

Emitter(Search.prototype);

export default Search;
