class RandomiseList {
  /*
  Randomise a list of items
  */
  constructor() {
    this.lists = document.querySelectorAll("[data-randomise]");
    this.init();
  }

  init() {
    this.lists.forEach((list) => {
      if (list.children.length == 0) {
        return;
      }
      for (var i = list.children.length; i >= 0; i--) {
        list.appendChild(list.children[(Math.random() * i) | 0]);
      }
    });
  }
}

export default RandomiseList;
