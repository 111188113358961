import React from "react";
import Emitter from "component-emitter";
import { Basket as ReactBasket, DeleteModal } from "../react/Basket";
import { createStore, StoreProvider, action, thunk } from "easy-peasy";
import { createRoot } from 'react-dom/client';


class Basket {
    constructor() {
        this.init();
    }

    show() {
        this.popup.classList.add("is-active");
        this.emit("show");
    }

    hide() {
        this.popup.classList.remove("is-active");
        this.emit("hide");
    }

    init() {
        const toggle = document.querySelector("[data-basket-toggle]");
        this.basketCount = document.querySelectorAll("[data-basket-count]");
        this.popup = document.querySelector("[data-basket-popup]");
        const addToBasket = document.querySelectorAll("[data-add-to-basket]");
        const baskets = document.querySelectorAll(".basket");

        if (baskets.length == 0) {
            return;
        }

        const store = createStore({
            basket: {
                items: [],
                formatted_total: "0.00",
                formatted_shipping_total: "0.00",
                shipping_total: 0,
            },
            deleteItemId: null,
            updateBasket: action((state, payload) => {
                state.basket = payload;
            }),
            updateItemQuantity: thunk((actions, payload) => {
                const res = fetch("/shop/api/basket/", {
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        item_id: payload.itemId,
                        quantity: payload.quantity,
                    }),
                });
                return res
                    .then((res) => res.json())
                    .then((res) => actions.updateBasket(res));
            }),
            updateDeleteItemId: action((state, payload) => {
                state.deleteItemId = payload;
            }),
        });

        store.subscribe(() => {
            const itemsCount = store
                .getState()
                .basket.items.reduce((acc, item) => acc + item.quantity, 0);
            this.basketCount.forEach((el) => {
                if (itemsCount > 0) {
                    el.textContent = itemsCount;
                    el.style.display = "block";
                } else {
                    el.style.display = "none";
                }
            });
        });

        baskets.forEach((el) => {
            let readOnly = false;
            if ("readonly" in el.dataset) {
                readOnly = true;
            }
            const root = createRoot(el)
            root.render(
                <StoreProvider store={store}>
                    <ReactBasket readOnly={readOnly} />
                </StoreProvider>
            );
        });
        const root = createRoot(document.getElementById("delete-modal"));

        root.render(
            <StoreProvider store={store}>
                <DeleteModal />
            </StoreProvider>
        );

        toggle.addEventListener("click", (e) => {
            if (this.popup.classList.contains("is-active")) {
                this.hide();
            } else {
                this.show();
            }
            e.preventDefault();
        });

        addToBasket.forEach((button) => {
            const quantityEl = button.parentElement.querySelector("[name=quantity]");
            const productSelectRadio = button.closest("form").querySelectorAll("input[type=radio]")

            productSelectRadio.forEach((el) => {
                el.addEventListener("change", (e) => {
                    if (!el.dataset.productId || (el.dataset.remaining && el.dataset.remaining == 0)) {
                        button.disabled = true
                    } else {
                        button.disabled = false
                    }
                })

                if (el.checked && el.dataset.remaining == 0) {
                    button.disabled = true;
                }
            })

            const inputs = button.parentElement.querySelectorAll("input");
            if (inputs) {
                inputs.forEach((el) => {
                    el.addEventListener("change", (e) => {
                        button.classList.remove("is-complete");
                    });
                });
            }
            button.addEventListener("click", (e) => {
                const productEl = button.parentElement.querySelector(
                    "[name=item_id]:checked"
                );

                let quantity = 1;
                let itemId = button.dataset.addToBasket;
                if (quantityEl) {
                    quantity = parseInt(quantityEl.value);
                }
                if (productEl) {
                    itemId = productEl.dataset.productId;
                }
                e.preventDefault();
                button.classList.add("is-loading");
                button.classList.remove("is-complete");
                button.disabled = true;
                let existingItem = store
                    .getState()
                    .basket.items.filter((item) => item.item_id === itemId);
                if (existingItem.length > 0) {
                    quantity = existingItem[0].quantity + quantity;
                }
                store
                    .getActions()
                    .updateItemQuantity({ itemId: itemId, quantity: quantity })
                    .then(() => {
                        button.classList.remove("is-loading");
                        button.classList.add("is-complete");
                        button.disabled = false;
                        setTimeout(() => {
                            button.classList.remove("is-complete");
                        }, 3000);
                    });
            });
        });
    }
}

Emitter(Basket.prototype);

export default Basket;
