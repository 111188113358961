class DependentDropdowns {
    constructor() {
        this.init();
    }

    init() {
        const dropdownSets = document.querySelectorAll(
            "[data-dependent-dropdowns]"
        );
        if (!dropdownSets) {
            return;
        }

        for (const dropdownSet of dropdownSets) {
            this.buildDropdownSet(dropdownSet);
        }
    }

    buildDropdownSet(dropdownSet) {
        const dropdowns = Array.from(dropdownSet.querySelectorAll("select"));

        // Store the current value of each dropdown in a list
        const values = dropdowns.map((dropdown) => {
            return dropdown.value;
        });

        this.updateDropdowns(dropdowns);

        // Restore the values of the dropdowns
        dropdowns.forEach((dropdown, index) => {
            dropdown.value = values[index];
        });

        dropdowns.forEach((dropdown) => {
            dropdown.addEventListener("change", (e) => {
                this.updateDropdowns(dropdowns, e.target);
            });
        });
    }

    updateDropdowns(dropdowns, target) {
        for (const dropdown of dropdowns) {
            if (dropdowns.indexOf(dropdown) === 0 || dropdown === target) {
                continue;
            }
            const parentValue =
                dropdowns[dropdowns.indexOf(dropdown) - 1].value;
            this.enableOptionsForParent(dropdown, parentValue);
        }
    }

    enableOptionsForParent(dropdown, parent) {
        const options = dropdown.querySelectorAll("option");
        if (!dropdown.dataset.options) {
            dropdown.dataset.options = JSON.stringify(
                Array.from(options).map((option) => {
                    return {
                        value: option.value,
                        parent: option.dataset.parent,
                    };
                })
            );
        }
        options.forEach((option) => {
            option.remove();
        });

        JSON.parse(dropdown.dataset.options).forEach((option) => {
            if (option.parent === parent) {
                const newOption = document.createElement("option");
                newOption.value = option.value;
                newOption.textContent = option.value;
                newOption.dataset.parent = option.parent;
                dropdown.appendChild(newOption);
            }
        });

        // Hide if no options
        if (dropdown.querySelectorAll("option").length === 0) {
            dropdown.parentNode.style.display = "none";
        } else {
            dropdown.parentNode.style.display = "flex";
        }
    }
}

export default DependentDropdowns;
