class RandomBg {
  constructor() {
    this.randomBgContainers = document.querySelectorAll(
      "[data-random-bg-images]"
    );
    this.init();
  }

  init() {
    this.randomBgContainers.forEach(container => {
      const images = container.dataset.randomBgImages.split(",");
      images.pop();
      let randomImage = images[Math.floor(Math.random() * images.length)];
      container.style.backgroundImage = "url(" + randomImage + ")";
    });
  }
}

export default RandomBg;
