class Notifications {
  constructor() {
    this.init();
  }

  init() {
    this.notifications = document.querySelectorAll(".notification");
    this.notifications.forEach((item) => {
      const dismissButton = item.querySelector(".notification__dismiss");
      if (!dismissButton) {
        return;
      }
      dismissButton.addEventListener("click", (event) => {
        item.classList.add("is-dismissed");
        setTimeout(() => {
          item.parentNode.removeChild(item);
        }, 300);
      });
    });
  }
}

export default Notifications;
