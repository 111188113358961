import Emitter from "component-emitter";

class DesktopMenu {
  constructor() {
    this.menu = document.querySelector(".nav-primary-desktop");
    this.activeItem = null;
    this.init();
  }

  reset() {
    if (this.activeItem) {
      this.activeItem.classList.remove("is-active");
    }
  }

  onHover(event) {
    event.target.classList.add("is-active");
    this.activeItem = event.target;
    this.emit("show", event);
  }

  onHoverOut(event) {
    event.target.classList.remove("is-active");
    this.activeItem = null;
    this.emit("hide");
  }

  init() {
    if (!this.menu) {
      return;
    }
    // Replace the menu CSS hover with a JS hover, so we
    // we can dismiss the menu on navigation
    this.menu.classList.add("is-js");
    const menuItems = this.menu.querySelectorAll(
      ".nav-primary-desktop__item--dropdown"
    );
    menuItems.forEach((item) => {
      item.addEventListener("mouseenter", this.onHover.bind(this));
      item.addEventListener("mouseleave", this.onHoverOut.bind(this));
    });
  }
}
Emitter(DesktopMenu.prototype);

export default DesktopMenu;
