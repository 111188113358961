class Confirm {
  constructor() {
    this.init();
  }

  init() {
    const confirmables = document.querySelectorAll("[data-confirm-action]");
    const confirmModal = document.querySelector("[data-confirm-modal]");
    if (!confirmModal || !confirmables) {
      return;
    }
    const affirmative = confirmModal.querySelector(
      "[data-confirm-modal-affirmative]"
    );
    const cancel = confirmModal.querySelector("[data-confirm-modal-cancel]");

    cancel.addEventListener("click", ev => {
      confirmModal.classList.remove("is-active");
    });

    confirmables.forEach(confirmable => {
      confirmable.addEventListener("click", ev => {
        if (!("intercepted" in confirmable.dataset)) {
          ev.preventDefault();
          confirmModal.classList.add("is-active");
          affirmative.addEventListener("click", ev => {
            confirmable.dataset.intercepted = "true";
            confirmable.click();
          });
        }
      });
    });
  }
}

export default Confirm;
